/* eslint-disable autofix/no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useCallback } from 'react';

import useUserCustomSessionExpiryQuery from './queries/useUserCustomSessionExpiryQuery';

import { isPageInIframe } from '../utility/CommonUtility';

const WARNING_TIME = 5 * 60 * 1000; // Warning time before logout - TODO: Adjust as needed
const ACTIVITY_EVENT = 'user-activity';
const STAY_SIGNED_IN = 'stay-signed-in';
const LOGOUT_EVENT = 'user-logout';
const SHOW_LOGOUT_WARNING = 'show-logout-warning';
const EVENTS = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'];

type PostMessageActionsObject = Array<{ type: string; value: unknown }>;

type PostMessageDataObject = {
  type: 'activity_event' | 'integration_message';
  actions?: PostMessageActionsObject;
};

type MessageDataType = {
  source: string;
  action: string;
  message: string;
};

const useAutoLogout = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const warningTimerRef = useRef<NodeJS.Timeout | null>(null);
  const storageListenerRef = useRef<((event: StorageEvent) => void) | null>(null);
  const isListeningRef = useRef<boolean>(false); // Tracks whether event listeners are active

  const [inactivityTimeout, setInactivityTimeout] = useState<number | null>(null);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [isInIframe, setInIframe] = useState<boolean>(false);

  const { data: sessionExpiry } = useUserCustomSessionExpiryQuery();

  /**
   * This clears all the timers
   */
  const clearAllTimers = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
      warningTimerRef.current = null;
    }
  }, []);

  /**
   * This starts all activity listeners
   */
  const addActivityListeners = () => {
    if (isListeningRef.current) return;
    isListeningRef.current = true;

    if (!isInIframe) {
      console.log('DDD', 'APP-UI', 'Adding ACTIVITY event listeners');
      EVENTS.forEach((event) => window.addEventListener(event, resetTimer));
    }
  };

  /**
   * This removes all activity listeners
   */
  const removeActivityListeners = () => {
    if (!isListeningRef.current) return;
    isListeningRef.current = false;

    if (!isInIframe) {
      console.log('DDD', 'APP-UI', 'Removing ACTIVITY event listeners');
      EVENTS.forEach((event) => window.removeEventListener(event, resetTimer));
    }
  };

  const sendMessageToParent = (action: string, message: string = '') => {
    try {
      const baseUrl = window.location.origin;
      window.parent.postMessage({ source: 'ccs-app-ui-service', action, message }, baseUrl);
    } catch {
      console.warn('MessageCommunicator', 'Parent unauthorized or noneexistant');
    }
  };

  /**
   * This function resets the timers whenever a activity is detected
   */
  const resetTimer = () => {
    if (!inactivityTimeout) return;

    console.log('DDD', 'APP-UI', 'ACTIVITY DETECTED!');

    clearAllTimers();
    setShowWarning(false);

    // Sync activity across tabs
    localStorage.setItem(ACTIVITY_EVENT, Date.now().toString());

    // Start inactivity countdown
    timerRef.current = setTimeout(() => {
      localStorage.setItem(SHOW_LOGOUT_WARNING, Date.now().toString());
      showLogoutWarning();
    }, inactivityTimeout - WARNING_TIME);
  };

  /**
   * This triggers the logout warning
   */
  const showLogoutWarning = () => {
    console.log('DDD', 'APP-UI', 'Showing Logout warning dialog');

    clearAllTimers();
    removeActivityListeners(); // 🔥 STOP LISTENING TO USER ACTIVITY

    console.log('DDD', 'APP-UI', 'isInIframe:', isInIframe);

    if (!isInIframe) {
      setShowWarning(true);
    } else {
      sendMessageToParent(SHOW_LOGOUT_WARNING);
    }

    warningTimerRef.current = setTimeout(logoutUser, WARNING_TIME);
  };

  /**
   * This is the handler function for the stay signed in button on the warning dialog
   */
  const staySignedIn = () => {
    clearAllTimers();
    setShowWarning(false);
    addActivityListeners(); // 🔥 RE-ENABLE ACTIVITY LISTENERS
    resetTimer();
  };

  /**
   * This is the handler function for the logout button on the warning dialog
   */
  const logoutUser = useCallback(() => {
    clearAllTimers();

    console.log('DDD', 'APP-UI', 'isInIframe:', isInIframe);

    if (!isInIframe) {
      console.log('DDD', 'APP-UI', 'Redirecting to logout');
      window.location.href = `${window.location.origin}/logout`;
    } else {
      console.log('DDD', 'APP-UI', 'Sending logout message to webapp');
      // sendMessageToParent(LOGOUT_EVENT);
      parent.window.location.href = `${window.location.origin}/logout`;
    }
  }, [clearAllTimers, isInIframe]);

  // This is the useEffect section of the hook

  /**
   * Main effect, this one detects changes on team settigns and updates the hook
   */
  useEffect(() => {
    console.log('DDD', 'APP-UI', 'Running the teamSettings effect');

    const inIframe = isPageInIframe();

    setInIframe(inIframe);

    if (inIframe) {
      console.log('DDD', 'APP-UI', 'This page is inside an iframe.');
    } else {
      console.log('DDD', 'APP-UI', 'This page is not inside an iframe.');
    }

    if (sessionExpiry?.max_ttl) {
      console.log('DDD', 'APP-UI', 'Setting the new TTL');
      setInactivityTimeout(sessionExpiry.max_ttl * 60 * 1000);
    } else {
      setInactivityTimeout(null);
    }
  }, [sessionExpiry]);

  useEffect(() => {
    if (!inactivityTimeout) return;

    addActivityListeners();

    storageListenerRef.current = (event: StorageEvent) => {
      switch (event.key) {
        case SHOW_LOGOUT_WARNING:
          showLogoutWarning();
          break;
        case LOGOUT_EVENT:
          logoutUser();
          break;
        case STAY_SIGNED_IN:
          staySignedIn();
          break;
        case ACTIVITY_EVENT:
          resetTimer();
          break;
        default:
          break;
      }
    };

    window.addEventListener('storage', storageListenerRef.current);

    if (isInIframe) {
      console.log('DDD', 'APP-UI', 'Adding MESSAGE event listeners');
      window.addEventListener('message', handleMessageEvents);
    }

    resetTimer();

    return () => {
      removeActivityListeners();

      if (storageListenerRef.current) {
        window.removeEventListener('storage', storageListenerRef.current);
      }

      if (isInIframe) {
        console.log('DDD', 'APP-UI', 'Removing MESSAGE event listeners');
        window.removeEventListener('message', handleMessageEvents);
      }

      clearAllTimers();
    };
  }, [inactivityTimeout]);

  const handleMessageEvents = (event: { data: MessageDataType }) => {
    const data = event.data;
    switch (data.action) {
      case LOGOUT_EVENT:
        logoutUser();
        break;
      case STAY_SIGNED_IN:
        staySignedIn();
        break;
      case ACTIVITY_EVENT:
        resetTimer();
        break;
      default:
        break;
    }
  };

  const handleStaySignedIn = () => {
    localStorage.setItem(STAY_SIGNED_IN, Date.now().toString());
    staySignedIn();
  };

  const handleLogout = () => {
    localStorage.setItem(LOGOUT_EVENT, Date.now().toString());
    logoutUser();
  };

  return { showWarning, handleStaySignedIn, handleLogout };
};

export default useAutoLogout;
