export const de = {
  teamActivityFeed: 'Team-Aktivitäts-Feed',
  guest: 'Gast',
  you: 'Sie',
  and: ' und ',
  editNotification: {
    madeChanges: ' haben auf ',
    madeChangesEnd: ' gearbeitet.',
    heMadeChanges: ' hat auf ',
  },
  aboard: 'einem Board',
  inviteToBoardNotification: {
    hasInvited1: ' hat ',
    hasInvited2: ' eingeladen.',
    haveInvited1: ' haben ',
    asAn: ' als ',
    reader: 'Betrachter',
    reviewer: 'Kommentator',
    editor: 'Bearbeiter',
    owner: 'Besitzer',
    to: ' zu ',
  },
  cancelApprovalNotification: {
    canceledTheApprovalOn1: ' hat die Freigabe auf ',
    canceledTheApprovalOn2: ' abgebrochen.',
    canceledAnApproval: ' hat eine Freigabe abgebrochen.',
  },
  assignedATaskTo1: ' hat ',
  assignedATaskTo2: ' eine Aufgabe auf ',
  assignedATaskTo3: ' zugewiesen.',
  toYou: 'Ihnen',
  reply: 'antworten',
  mentioned1: ' hat ',
  mentioned2: ' auf ',
  mentioned3: ' erwähnt.',
  today: 'Heute',
  yesterday: 'Gestern',
  loadMore: 'weitere laden',
  openBoardHistory: 'Board History öffnen',
  replyNotification: {
    repliedTo1: ' hat ',
    repliedTo2: ' auf ',
    repliedTo3: ' geantwortet.',
  },
  boardUpdateNotification: {
    postedAnUpdateFor1: ' hat ein Update',
    postedAnUpdateFor2: ' auf ',
    postedAnUpdateFor3: ' gepostet.',
  },
  invitationNotification: {
    youHaveRecommendedConceptboard1: 'Sie haben ',
    youHaveRecommendedConceptboard2: ' Conceptboard empfohlen. Danke schön!',
  },
  deletionNotification: {
    iHaveDeleted1: 'Sie haben das ',
    iHaveDeleted2: ' gelöscht.',
    userHasDeleted1: ' hat das ',
    board: 'Board ',
    project: 'Projekt ',
  },
  accountCreatedNotification: {
    iCreatedAccount1: " heißt Sie willkommen an Bo(a)rd! Danke für's Anmelden, jetzt ",
    iCreatedAccount2: '!',
    userHasCreatedAccount: ' hat sich bei Conceptboard angemeldet.',
    linkName: 'probieren Sie sein interaktives Tutorial aus',
  },
  emailConfirmationNotification: {
    iHaveConfirmed1: ' haben Ihre E-Mail-Adresse ',
    iHaveConfirmed2:
      ' bestätigt. Nun ist Ihr Konto voll aktiviert und Sie erhalten Einladungen und Meldungen per E-Mail.',
    userHasConfirmed:
      ' hat die E-Mail-Adresse bestätigt. Nun ist sein/ihr Konto voll aktiviert und er/sie erhält Einladungen und Meldungen per E-Mail.',
  },
  companyJoinNotification: {
    iJoinedTheTeam1: ' sind dem Team ',
    iJoinedTheTeam2: ' beigetreten.',
    userHasJoinedTheTeam: ' ist Ihrem Team beigetreten.',
  },
  welcomeInviteNotification: {
    worksBestForTeams1: 'Conceptboard funktioniert am besten für Teams. ',
    worksBestForTeams2: 'Prüfen Sie Ihre Nutzerliste',
    worksBestForTeams3: ' und laden Sie Leute zur Mitarbeit ein!',
  },
  welcomeVideoNotification: {
    gettingStartedVideo1: ' empfiehlt Ihnen Conceptboards Getting-Started-Video. ',
    gettingStartedVideo2: "Schauen Sie's sich jetzt an",
    gettingStartedVideo3: ' oder werfen Sie einen Blick ins ',
    gettingStartedVideo4: 'Help-Center',
    gettingStartedVideo5: ' für weitere Videos.',
    videoLinkAlt: 'Conceptboards Getting-Started-Video.',
    helpCentreUrl: 'https://help.conceptboard.com/hc/de',
  },
  onlineUsersNotification: {
    sectionName: 'Jetzt online',
    onlineBadge: 'online',
    areWorking: ' arbeiten an ',
    isWorking: ' arbeitet an ',
  },
  pendingNotifications: {
    sectionName: 'Ausstehende Freigaben',
    needsApproval: ' benötigt Ihre Freigabe auf ',
    dueUntil: 'fällig bis ',
    overdue: 'überfällig seit ',
  },
  approvalVoteNotification: {
    updated1: ' hat auf ',
    updated2: ' die Freigabe aktualisiert.',
    rejected1: ' hat auf ',
    rejected2: ' die Freigabe verweigert.',
    approved1: ' hat ',
    approved2: ' freigegeben.',
    approvedWithEdits1: ' hat ',
    approvedWithEdits2: ' mit Änderungen freigegeben.',
    pdfLink: 'Version als PDF herunterladen',
  },
  approvalStartedNotification: {
    startedAnApproval1: ' hat eine Freigabe',
    startedAnApproval2: ' auf ',
    startedAnApproval3: ' mit ',
    startedAnApproval4: ' gestartet.',
    deadline: 'Fristende: ',
  },
  loadMoreButton: 'weitere Aktivitäten anzeigen',
};
