export const en = {
  teamActivityFeed: 'Team activity feed',
  guest: 'Guest',
  you: 'You',
  and: ' and ',
  editNotification: {
    madeChanges: ' made changes on ',
    madeChangesEnd: '.',
    heMadeChanges: ' made changes on ',
  },
  aboard: 'a board',
  inviteToBoardNotification: {
    hasInvited1: ' has invited ',
    hasInvited2: '.',
    haveInvited1: ' have invited ',
    asAn: ' as an ',
    reader: 'Reader',
    reviewer: 'Reviewer',
    editor: 'Editor',
    owner: 'Owner',
    to: ' to ',
  },
  cancelApprovalNotification: {
    canceledTheApprovalOn1: ' cancelled the approval on ',
    canceledTheApprovalOn2: '.',
    canceledAnApproval: ' cancelled an approval.',
  },
  assignedATaskTo1: ' assigned a task to ',
  assignedATaskTo2: ' on ',
  assignedATaskTo3: '.',
  toYou: 'you',
  reply: 'reply',
  mentioned1: ' mentioned ',
  mentioned2: ' on ',
  mentioned3: '.',
  today: 'Today',
  yesterday: 'Yesterday',
  loadMore: 'Load more',
  openBoardHistory: 'Open board history',
  replyNotification: {
    repliedTo1: ' replied to ',
    repliedTo2: ' on ',
    repliedTo3: '.',
  },
  boardUpdateNotification: {
    postedAnUpdateFor1: ' posted an update',
    postedAnUpdateFor2: ' for ',
    postedAnUpdateFor3: '.',
  },
  invitationNotification: {
    youHaveRecommendedConceptboard1: 'You have recommended Conceptboard to ',
    youHaveRecommendedConceptboard2: '. Thank you!',
  },
  deletionNotification: {
    iHaveDeleted1: 'You have deleted the ',
    iHaveDeleted2: '.',
    userHasDeleted1: ' has deleted the ',
    board: 'board ',
    project: 'project ',
  },
  accountCreatedNotification: {
    iCreatedAccount1: ' welcomes you onboard! Thanks for signing up, now ',
    iCreatedAccount2: '!',
    userHasCreatedAccount: ' created an account at Conceptboard.',
    linkName: 'try his interactive tutorial',
  },
  emailConfirmationNotification: {
    iHaveConfirmed1: ' have confirmed your email address ',
    iHaveConfirmed2:
      '. Now your account is fully activated and you can receive invitations and notifications via email.',
    userHasConfirmed:
      ' has confirmed the email address. Now his/her account is fully activated and he/she can receive invitations and notifications via email.',
  },
  companyJoinNotification: {
    iJoinedTheTeam1: ' joined the team ',
    iJoinedTheTeam2: '.',
    userHasJoinedTheTeam: ' joined your team.',
  },
  welcomeInviteNotification: {
    worksBestForTeams1: 'Conceptboard works best for teams. ',
    worksBestForTeams2: 'Check your list of users',
    worksBestForTeams3: ' and invite people to work with you!',
  },
  welcomeVideoNotification: {
    gettingStartedVideo1: " recommends Conceptboard's Getting Started Video. ",
    gettingStartedVideo2: 'Watch it now',
    gettingStartedVideo3: ' or take a look at more videos in the ',
    gettingStartedVideo4: 'help centre',
    gettingStartedVideo5: '.',
    videoLinkAlt: "Conceptboard's Getting Started Video",
    helpCentreUrl: 'https://help.conceptboard.com/hc/en-us',
  },
  onlineUsersNotification: {
    sectionName: 'Currently online',
    onlineBadge: 'online',
    areWorking: ' are working on ',
    isWorking: ' is working on ',
  },
  pendingNotifications: {
    sectionName: 'Pending approvals',
    needsApproval: ' needs your approval on ',
    dueUntil: 'due until ',
    overdue: 'overdue since ',
  },
  approvalVoteNotification: {
    updated1: ' updated the approval on ',
    updated2: '.',
    rejected1: ' did not approve ',
    rejected2: ' did not approve ',
    approved1: ' approved ',
    approved2: '.',
    approvedWithEdits1: ' accepted with edits ',
    approvedWithEdits2: '.',
    pdfLink: 'Download this version as PDF',
  },
  approvalStartedNotification: {
    startedAnApproval1: ' started an approval',
    startedAnApproval2: ' on ',
    startedAnApproval3: ' with ',
    startedAnApproval4: '.',
    deadline: 'Deadline: ',
  },
  loadMoreButton: 'Load more activities',
};
